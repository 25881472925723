import { Firefly } from '../assets';
import { Layout } from '../components';
import AccentButton from '../components/AccentButton';
import Input from '../components/Input';
import { useState } from 'react';
import useIdentifications from '../hooks/useIdentifications';

const DeleteAccount = () => {
    const [identification, setIdentification] = useState('');
    const { submitIdentification } = useIdentifications();

    const submitRequest = () => {
        submitIdentification(identification);
    };

    return (
        <Layout className="bg-gray-50">
            <div className="flex flex-col justify-center items-center px-5 py-12 w-full h-full bg-gray-50">
                <div className="flex flex-col items-center mb-8 max-w-lg text-center">
                    <Firefly />
                    <h1 className="px-6 mt-12 text-2xl font-bold">
                        Solicitação para exclusão de conta do aplicativo Firefly
                    </h1>
                    <p className="px-6 mt-8 text-gray-500">
                        Por favor, informe o e-mail ou telefone da conta de usuário que deseja
                        solicitar a exclusão.
                    </p>
                    <p className="px-6 mt-4 text-gray-500">
                        A sua solicitação será processada em até 7 dias úteis.
                    </p>
                    <div className="flex flex-col items-center py-12 mt-8 w-full bg-white rounded-lg shadow-md">
                        <div className="w-full max-w-sm">
                            <Input
                                name="identification"
                                label="Email ou Telefone"
                                value={identification}
                                onChange={(e: any) => setIdentification(e.target.value)}
                            />
                            <div className="mt-8">
                                <AccentButton onClick={submitRequest}>Excluir Conta</AccentButton>
                            </div>
                        </div>
                    </div>

                    <div className="px-6 mt-8 text-left">
                        <p className="font-bold">Suporte ao Usuário</p>
                        <p className="text-gray-500">
                            Se você tiver dúvidas ou encontrar problemas, entre em contato conosco
                            pelo e-mail:{' '}
                            <span className="text-accent-400">contatofirefly78@gmail.com</span>
                        </p>
                    </div>
                    <div className="px-6 mt-4 text-left">
                        <p className="font-bold">Política de Privacidade</p>
                        <p className="text-gray-500">
                            Para mais informações sobre como lidamos com seus dados, consulte nossa 
                            <a
                                href="https://fireflycharge.com.br/sitefirefly/Politica_de_privacidade_FIREFLY.htm"
                                target="_blank"
                                className="border-b border-accent-400 text-accent-400"
                                rel="noreferrer"
                            >
                                Política de Privacidade
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DeleteAccount;
