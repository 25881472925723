import supabase from '../config/supabase';
import { toast } from '../config/toast';

const useIdentifications = () => {
    const submitIdentification = async (identification: string) => {
        if (!identification) return;

        const { error } = await supabase.from('identifications').insert({ identification });

        if (error) {
            toast.error(error.message);
        } else {
            toast.success('Solicitação enviada com sucesso');
        }
    };

    return { submitIdentification };
};
export default useIdentifications;
