import { createId } from '@paralleldrive/cuid2';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitialState {
    cards: Array<Card>;
}

const initialState: InitialState = {
    cards: [],
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        addCard: (state, action: PayloadAction<Omit<Card, 'id'>>) => {
            state.cards.push({
                ...action.payload,
                id: createId(),
            });
        },
        removeCard: (state, { payload: cardId }: PayloadAction<string>) => {
            const cardIndex = state.cards.findIndex((card) => card.id === cardId);
            state.cards.slice(cardIndex, 1);
        },
    },
});

// selectors
export const getCards = (state: RootState) => state.application.cards;

// actions & reducer
export const { actions, reducer } = applicationSlice;
