import { FC } from 'react';
import { ButtonLoading } from '../assets';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode;
    loading?: boolean;
    color?: 'accent' | 'blue' | 'green';
};

const AccentButton: FC<Props> = ({ children, loading, className, color = 'accent', ...props }) => {
    const isDisabled = props.disabled || loading;

    const colors = {
        accent: 'bg-accent-400 hover:bg-accent-600 focus-visible:outline-accent-400',
        blue: 'bg-blue-500 hover:bg-blue-600 focus-visible:outline-blue-500',
        green: 'bg-green-500 hover:bg-green-600 focus-visible:outline-green-500',
    };

    const selectedColor = colors[color];

    return (
        <button
            {...props}
            disabled={isDisabled}
            className={`flex w-full justify-center items-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-gray-300 disabled:pointer-events-none ${selectedColor} ${className}`}
        >
            {loading ? (
                <div className="relative bottom-0.5 translate-x-1.5 translate-y-0.5">
                    <ButtonLoading />
                </div>
            ) : (
                children
            )}
        </button>
    );
};
export default AccentButton;
