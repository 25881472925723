import { FC } from 'react';

const Input: FC<any> = ({ name, label, className, type, required, ...props }) => {
    return (
        <div className={`w-full max-w-full sm:w-96 ${className}`}>
            <label
                htmlFor={name}
                className="flex gap-1 text-sm font-bold leading-6 text-gray-900 whitespace-nowrap"
            >
                {label || name}
            </label>
            <div className="mt-2">
                {' '}
                <input
                    id={name}
                    type={type}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-gray-500"
                    required={required}
                    {...props}
                />
            </div>
        </div>
    );
};
export default Input;
