import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import DeleteAccount from './screens/DeleteAccount';
import { ToastContainer } from 'react-toastify';

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <DeleteAccount />
            </PersistGate>
            <ToastContainer />
        </Provider>
    );
}

export default App;
