import { toast as _toast } from 'react-toastify';

export const config = {
    position: 'top-right' as const,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light' as const,
};

export const toast = {
    error: (message: string, description?: string) =>
        setTimeout(() => _toast.error(message, config), 0),
    success: (message: string) => setTimeout(() => _toast.success(message, config), 0),
};
