import { FC } from 'react';

const Layout: FC<any> = ({ children, className }) => {
    return (
        <div className={`flex flex-1 justify-center min-h-screen ${className}`}>
            <div className="w-full max-w-6xl">{children}</div>
        </div>
    );
};
export default Layout;
